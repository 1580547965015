require('echarts/theme/macarons'); // echarts theme
import resize from '@/mixins/resize';
import continents_data from '@/assets/map/continent_geo.json';
export default {
  name: "queryMap",
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    title: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '350px'
    },
    autoResize: {
      type: Boolean,
      default: true
    },
    chartData: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  watch: {
    chartData: {
      deep: true,
      handler: function handler(val) {
        this.setOptions(val);
      }
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.initChart();
    });
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      this.chart = this.$echarts.init(this.$el, 'macarons');
      this.setOptions(this.chartData);
    },
    setOptions: function setOptions() {
      var _this$chart2, _this$chart3;
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        data = _ref.data,
        loading = _ref.loading;
      if (loading) {
        var _this$chart;
        (_this$chart = this.chart) === null || _this$chart === void 0 ? void 0 : _this$chart.showLoading();
        return;
      }
      (_this$chart2 = this.chart) === null || _this$chart2 === void 0 ? void 0 : _this$chart2.hideLoading();
      this.$echarts.registerMap('CONTINENTS', continents_data);
      (_this$chart3 = this.chart) === null || _this$chart3 === void 0 ? void 0 : _this$chart3.setOption({
        title: {
          text: this.title || 'Traffic map',
          textStyle: {
            fontSize: 13,
            fontWeight: 'bold',
            color: '#464646'
          }
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b}<br/>{c} (%)'
        },
        visualMap: {
          min: 0,
          max: 100,
          text: ['High', 'Low'],
          realtime: false,
          calculable: true,
          inRange: {
            // color: ['#EDFE6E', '#4BC6C5', '#4586CF']
            color: ['lightskyblue', 'yellow', 'orangered']
          }
        },
        series: [{
          name: '各大洲请求情况',
          type: 'map',
          map: 'CONTINENTS',
          label: {
            show: true
          },
          itemStyle: {
            normal: {
              borderWidth: 0
            },
            emphasis: {
              label: {
                show: true
              }
            }
          },
          data: data,
          // [
          //   {name:'Europe', value: 6.7},
          //   {name:'Oceania', value: 0.14},
          //   {name:'Africa', value: 0.02},
          //   {name:'Asia', value: 79.41},
          //   {name:'North America', value: 13.56},
          //   {name:'South America', value: 0.17},
          // ],
          // 自定义名称映射
          nameMap: {
            '亚洲': 'AS',
            '大洋洲': 'OC',
            '欧洲': 'EU',
            '北美洲': 'NA',
            '南美洲': 'SA',
            '非洲': 'AF'
          },
          zoom: 1.2
        }]
      });
    }
  }
};