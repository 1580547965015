import service from '@/utils/request';

// @Tags HcTask
// @Summary 创建HcTask
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.HcTask true "创建HcTask"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /bizHcTask/createHcTask [post]
export var createHcTask = function createHcTask(data) {
  return service({
    url: "/bizHcTask/createHcTask",
    method: 'post',
    data: data
  });
};

// @Tags HcTask
// @Summary 删除HcTask
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.HcTask true "删除HcTask"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /bizHcTask/deleteHcTask [delete]
export var deleteHcTask = function deleteHcTask(data) {
  return service({
    url: "/bizHcTask/deleteHcTask",
    method: 'delete',
    data: data
  });
};

// @Tags HcTask
// @Summary 删除HcTask
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除HcTask"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /bizHcTask/deleteHcTask [delete]
export var deleteHcTaskByIds = function deleteHcTaskByIds(data) {
  return service({
    url: "/bizHcTask/deleteHcTaskByIds",
    method: 'delete',
    data: data
  });
};

// @Tags HcTask
// @Summary 更新HcTask
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.HcTask true "更新HcTask"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /bizHcTask/updateHcTask [put]
export var updateHcTask = function updateHcTask(data) {
  return service({
    url: "/bizHcTask/updateHcTask",
    method: 'put',
    data: data
  });
};

// @Tags HcTask
// @Summary 用id查询HcTask
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.HcTask true "用id查询HcTask"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /bizHcTask/findHcTask [get]
export var findHcTask = function findHcTask(params) {
  return service({
    url: "/bizHcTask/findHcTask",
    method: 'get',
    params: params
  });
};

// @Tags HcTask
// @Summary 分页获取HcTask列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取HcTask列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /bizHcTask/getHcTaskList [get]
export var getHcTaskList = function getHcTaskList(params) {
  return service({
    url: "/bizHcTask/getHcTaskList",
    method: 'get',
    //  donNotShowLoading: false,
    params: params
  });
};

// @Tags HcTask
// @Summary 获取最后更新时间
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "获取最后更新时间"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /hc/lastupdatetime [get]
export var getHCLastUpdateTime = function getHCLastUpdateTime(params) {
  return service({
    url: "/dnsapi/hc/lastupdatetime",
    method: 'get',
    // donNotShowLoading: true,
    params: params
  });
};

// @Tags HcTask
// @Summary 获取报警任务数量
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body true 获取报警任务数量
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /bizHcTask/getHcTaskList [get]
export var getAlarmCount = function getAlarmCount(params) {
  return service({
    url: "/bizHcTask/getAlarmCount",
    method: 'get',
    donNotShowLoading: true,
    params: params
  });
};

// @Tags HcTask
// @Summary 分页获取HcTask关联的域名
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取HcTask关联的域名"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /bizHcTask/getHcDomainByTaskId [get]
export var getHcDomainByTaskId = function getHcDomainByTaskId(params) {
  return service({
    url: "/bizHcTask/getHcDomainByTaskId",
    method: 'get',
    //  donNotShowLoading: false,
    params: params
  });
};

// @Tags HcTask
// @Summary 获取健康检查任务统计
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body null true "获取健康检查任务统计"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /bizHcTask/getHcTaskStat [get]
export var getHcTaskStat = function getHcTaskStat(params) {
  return service({
    url: "/bizHcTask/getHcTaskStat",
    method: 'get',
    //  donNotShowLoading: false,
    params: params
  });
};