import service from '@/utils/request';

// @Tags ZoneRR
// @Summary 创建ZoneRR
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.ZoneRR true "创建ZoneRR"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /Zone/createZoneRR [post]
export var createZoneRR = function createZoneRR(data) {
  return service({
    url: "/zones/" + data.zone,
    method: 'post',
    data: data
  });
};

// @Tags ZoneRR
// @Summary 删除ZoneRR
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.ZoneRR true "删除ZoneRR"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /Zone/deleteZoneRR [delete]
export var deleteZoneRR = function deleteZoneRR(data) {
  return service({
    url: "/zones/" + data.zone,
    method: 'delete',
    data: data
  });
};

// @Tags ZoneRR
// @Summary 删除ZoneRR
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除ZoneRR"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /Zone/deleteZoneRR [delete]
export var deleteZoneRRByIds = function deleteZoneRRByIds(data) {
  return service({
    url: "/zones/deleteZoneRRByIds",
    method: 'delete',
    data: data
  });
};

// @Tags ZoneRR
// @Summary 更新ZoneRR
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.ZoneRR true "更新ZoneRR"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /Zone/updateZoneRR [put]
export var updateZoneRR = function updateZoneRR(data) {
  return service({
    url: "/zones/updateZoneRR",
    method: 'put',
    data: data
  });
};

// @Tags ZoneRR
// @Summary 用id查询ZoneRR
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.ZoneRR true "用id查询ZoneRR"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /Zone/findZoneRR [get]
export var findZoneRR = function findZoneRR(params) {
  return service({
    url: "/zones/findZoneRR",
    method: 'get',
    params: params
  });
};

// @Tags ZoneRR
// @Summary 分页获取ZoneRR列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取ZoneRR列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /Zone/getZoneRRList [get]
export var getZoneRRList = function getZoneRRList(params) {
  return service({
    url: "/zones",
    method: 'get',
    params: params
  });
};

// @Tags ZoneRR
// @Summary 获取zone数量
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body true "获取zone数量"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /Zone/getZoneCountByTeam [get]
export var getZoneCountByTeam = function getZoneCountByTeam(params) {
  return service({
    url: "/zones/getZoneCountByTeam",
    method: 'put',
    params: params
  });
};

// @Tags ZoneRR
// @Summary 获取zone信息
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body true "获取zone信息"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /Zone/getZoneInfo [get]
export var getZoneInfo = function getZoneInfo(params) {
  return service({
    url: "/zones/getZoneInfo?zone=" + params.zone,
    method: 'put',
    donNotShowLoading: true
  });
};