var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    class: _vm.className,
    style: {
      height: _vm.height,
      width: _vm.width
    }
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };